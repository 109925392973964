@use '~/src/sass/abstracts/utils' as *;
@use '~/src/sass/abstracts/variables/colors';
@use '~/src/sass/abstracts/variables/structure';

.media {
	@include wrapper;
	height: 40rem;
}

.media__wrapper {
	height: 100%;
}

.media__wrapper .media__image,
.media__wrapper video,
.media__wrapper iframe {
	position: relative;
	height: 100%;
	border-radius: 20px;
	object-fit: cover;
	width: 100%;
}

.media__wrapper--50-50 {
	display: grid;
	grid-template-columns: 1fr;
	gap: 3rem;
}

.media__wrapper--50-50 .media__image {
	height: 30rem;
}

.media__wrapper--video {
	position: relative;
	height: 100%;
}

.media__wrapper--video .media__image {
	position: absolute;
	bottom: 0;
}

.media__wrapper--video .media__btn,
.media__wrapper--video .media__title {
	position: absolute;
	top: 50%;
	left: 50%;
}

.media__wrapper--video .media__btn {
	width: 7rem;
	height: 7rem;
	border: 1px solid colors.$white;
	color: colors.$white;
	border-radius: 50%;
	transition: left 200ms ease-in;
	transform: translate(-50%, -50%);
	background-color: transparent;

	i {
		font-size: 2.5rem;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-40%, -50%);
	}
}

.media__wrapper--video .media__btn:hover {
	cursor: pointer;
}

.media__wrapper--video .media__title {
	@extend %font-xl;
	transform: translateY(-50%);
	color: colors.$white;
	opacity: 0;
	font-weight: 900;
}

.media__wrapper--video:hover {
	.media__btn {
		left: 13%;
		background-color: colors.$white;
		color: colors.$blue;
	}

	.media__title {
		transition: opacity 100ms ease-in;
		transition-delay: 200ms;
		left: 26%;
		opacity: 1;
	}
}

.media__wrapper--video.media__wrapper--video-pause {
	.media__image {
		transition: max-height 400ms ease-in 400ms;
		max-height: 100%;
	}

	.media__title,
	.media__btn {
		transition: all 400ms ease-in 400ms;
		opacity: 1;
	}
}

.media__wrapper--video.media__wrapper--video-play {
	.media__image {
		transition: max-height 400ms ease-in 400ms;
		max-height: 0;
	}

	.media__btn,
	.media__title {
		opacity: 0;
	}

	.media__btn {
		transition: all 400ms ease-in 400ms;
		transform: translate(-50%, 200%);
	}

	.media__title {
		transition: all 200ms ease-in 400ms;
		transform: translateY(200%);
	}
}

@media screen and (min-width: structure.$tablet-portrait) {
	.media__wrapper--50-50 {
		grid-template-columns: repeat(2, minmax(1rem, 1fr));
	}

	.media__wrapper--video .media__title {
		max-width: 38%;
	}
}
